.App {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
}

.container3 {
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 2;
}

.modelContainer{
  position: absolute;
  height: 90%;
  width: 100%;
  z-index: 2;
}

.loadingScreen {
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
}
