.FilterSlider {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.titleName {
  width: 100%;
  height: 2rem;
  text-align: center;
  color: #1A3464;
  font-weight: 700;
  margin-top: 5px;
  font-size: .8rem;
}

.titleName > img {
  height: 100%;
}

.slider {
  height: 29%;
  width: 80%;
  margin-left: 1.5%;
  margin-right: 1.5%;
  background-color: #f2f2f2;
  align-self: center;
  margin-top: 15px;
  border-radius: 5px;
}

.tickHolder {
  margin-top: 2px;
  width: 80%;
  height: 10%;
  display: flex;
  flex-direction: row;
  border-left: solid 1px rgb(30,	60,	112);
  border-right: solid 1px rgb(30,	60,	112);
  align-self: center;
}

.tickBox {
  width: 33%;
  height: 100%;
  border-left: solid 1px rgb(30,	60,	112);
  border-right: solid 1px rgb(30,	60,	112);
}

.labelHolder{
  width: 83%;
  display: flex;
  height:fit-content;
  align-self: center;
}

.leftValue{
  width: 50%;
  height: fit-content;

  color: white;
}

.rightValue{
  width: 50%;
  height: fit-content;
  text-align: end;
  color: white;
}

.rc-slider-handle:before {
  content: attr(aria-valuenow);
  font-size: .75rem;
  color:white;
}

.value {
  font-weight: 600;
  font-size: 1.2rem;
  font-style: italic;
}