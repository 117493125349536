.newCards {
  bottom: 0;
  width: 100%;
  height: 95%;
  position: absolute;
}

.floorplanContainer {
  height: 87%;
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  padding-left: calc(10% - 5px);
  padding-right: calc(10% - 3px);
  justify-content: center;
  transform: rotate(0deg);
}

.viewerContainer {
  height: 100%;
  width: 99.3%;
  transform: rotate(0deg);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center
}

.floorplanViewer {
  overflow: scroll;
  -ms-overflow-style: none;
}

.floorplanViewer::-webkit-scrollbar {
  display: none;
}

.card {
  display: flex;
  flex-direction: column;
  height: 99%;
}

.square {
  display: flex;
  flex-direction: column;
  height: 49.9%;
}

.noCards {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 1rem 0;
}

.popUp {
  align-items: center;
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  border-radius: 12px;
  box-shadow: -0.1rem 0.1rem 0.3rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200);
  padding: 1rem;
}

.popUp > div:first-of-type {
  font-size: 1rem;
}
.popUp > div:nth-of-type(2) {
  font-size: 0.7rem;
  margin: 1rem 0;
}

.resetButton{
  background-color:#1E8ADC;
  width: 23%;
  color: white;
  border-radius: 3px;
  box-shadow: -0.1rem 0.1rem 0.3rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200);
  padding: 0.5rem;
}

.leftArrow {
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: 100% 100%; */
  width: 3rem;
  border-radius: 96%;
  position: absolute;
  top: calc(50% - 1.5rem);
  left: 2%;
  z-index: 5;
}

.rightArrow {
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: 100% 100%; */
  width: 3rem;
  border-radius: 96%;
  position: absolute;
  top: calc(50% - 1.5rem);
  right: 2%;
  z-index: 5;
}

.SliderHolder {
  height: 16%;
  width: 100%;
  background-color: transparent;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: 0px;
}

.sliderMargin {
  /* box-shadow: -0.1rem 0.1rem 0.3rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200); */
  box-shadow: 0 -4px 0.3rem rgb(200, 200, 200);
  height: 100%;
  width: 80%;
  background-color: #f2f2f2;
  display: flex;
  border-radius: 5px;
  position: relative;
}

.iframeBackground {
  position: fixed;
  height: 100vh;
  top: 0;
  width: 100%;
  z-index: 8;
  display: flex;
  background-color: black;
}

.iframeContainer {
  position: absolute;
  bottom: 0;
  height: calc(100% - 55px);
  width: 100%;
  z-index: 9;
  display: flex;
  background-color: white;
}

.iframeContainer iframe {
  border: none;
}

.title {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
}

.matterportExit {
  height: 8%;
  width: 15%;
  position: absolute;
  z-index: 11;
  font-size: .9rem;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%);
  background: none;
  border: none;
}

.sortBar {
  width: 78%;
  height: 5%;
  display: flex;
  color: blue;
  font-size: .8rem;
  position: absolute;
  top: -2%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}

.sortOptions {
  width: 50%;
  display: flex;
}

.resultsNum {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: #123643;
  font-size: .8rem;
  position: absolute;
  bottom: 0;
  right: 0; 
}

.communityDD {
  width: 47.5%;
  height: 112%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 99% 114%;
  /* box-shadow: -0.1rem 0.1rem 0.3rem rgb(200 200 200), 0.1rem 0.1rem 0.3rem rgb(200 200 200); */
  border-radius: 5px;
  font-family: 'Avenir';
  color: white;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 5px;
  margin-left: 1.5%;
  overflow: visible;
}

.communityText {
  font-family: 'Avenir';
  color: white;
  height: fit-content;
  
}

.quickMoveIn {
  width: 25%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  border-radius: 5px;
  font-family: 'Avenir';
  color: white;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
  box-shadow: -0.1rem 0.1rem 0.3rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200);
}

.switchStyle {
  height: 50px;
  width: 50px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  position: absolute;
  bottom: -52px;
  left: 50%;
  margin-left: -25px;
  border-radius: 5px;
}

.dropdown{
  background-color:red;
}

/* .qMoveFilter {
  color: #123643;
  width: 23%;
  height: 99%;
  display: flex;
  background-color: #f2f2f2;
  font-size: .7rem;
  top: -5%;
  z-index: 5;
  box-shadow: -0.1rem 0.1rem 0.3rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200);
  border-radius: 3px;
  margin-left: 1.8%;
  align-items: center;
}

.qMoveFilter p {
  flex: 6;
  text-align: center;
}

.qMoveFilter input {
  flex: 1;
} */

/* .modelFilter {
  width: 23%;
  height: 99%;
  display: flex;
  background-color: #f2f2f2;
  font-size: .7rem;
  top: -5%;
  z-index: 5;
  box-shadow: -0.1rem 0.1rem 0.3rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200);
  border-radius: 3px;
  margin-left: .5%;
  color: #123643;
  align-items: center;
}

.modelFilter p {
  flex: 6;
  text-align: center;
}

.modelFilter input {
  flex: 1;
} */

.filterBox {
  align-items: center;
  width: 23%;
  height: 99%;
  display: flex;
  background-color: #f2f2f2;
  font-size: .7rem;
  /* position: absolute; */
  top: -5%;
  z-index: 5;
  box-shadow: -0.1rem 0.1rem 0.3rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200);
  border-radius: 3px;
  /* border: 1px solid rgb(134, 134, 134); */
  margin-left: .5%;
  /* color: #123643; */
  padding: 0.5rem;
}

.filterBox:first-of-type {
  margin-right: 0.5rem;
}

.filterBox p {
  flex: 1;
  text-align: center;
}

.filterBox input[type=checkbox] {
  visibility: hidden;
}

.checkboxLabel {
  display: block;
  position: relative;
  cursor: pointer;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
}

.checkboxLabel {
  flex-shrink: 0;
  margin-right: 0.5rem;
}

.checkboxLabel:hover input ~ .checkbox {
  background-color: rgb(176, 185, 51);
}

.checkboxLabel input:active ~ .checkbox {
  background-color: rgb(176, 185, 51);
}

.checkboxLabel input:checked ~ .checkbox {
  background-color: rgb(176, 185, 51);
}

.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxLabel input:checked ~ .checkbox:after {
  display: block;
}
.checkboxLabel:hover input ~ .checkbox:after {
  display: block;
}
.checkboxLabel input:active ~ .checkbox:after {
  display: block;
}

.checkboxLabel .checkbox:after {
  left: 5px;
  bottom: 5px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
