.ButtonPanel {
  padding: 0.625rem;
}

.buttonWrapper {
  margin-bottom: 0.625rem;
}

.ButtonPanel button {
  background: #303030;
  border: 1px solid #fff;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .4);
  color: #fff;
  padding: 0.625rem;
  width: 47.5%;
}

.ButtonPanel button:first-child {
  margin-right: 5%;
}

.ButtonPanel button img {
  width: 23px;
}