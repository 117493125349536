.PhotoGallery {
  height: 75%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.selectContainer {
  margin-left: 1.25rem;
  position: relative;
  width: 250px;
  display: inline-table;
}

.PhotoGallery select {
  appearance: none;
  background-color: #191919;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 0.938rem;
  padding: 0.625rem;
  text-transform: capitalize;
  width: 100%;
}

.arrowDown {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  height: 0;
  pointer-events: none;
  position: absolute;
  right: 0.625rem;
  top: 50%;
  width: 0;
}

.galleryAndHeaderContainer{
  height:85%;
}

.galleryHeader {
  display: flex;
}

.exitButton {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0 0 0 0;
  position: absolute;
  transform: translateX(-50%);
  margin-left: 50%;
}