.Compare {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}

.modelWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 0.938rem;
  width: 100%;
}

.modelImg {
  max-width: 700px;
  width: calc(50% - 1.875rem);
}

.noImg {
  align-items: center;
  color: #707070;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  width: calc(50% - 1.875rem);
}

.noImg h2 {
  font-size: 1.188rem;
  font-weight: 400;
}

.noImg div {
  font-size: 0.938rem;
}

.divider {
  background: #1E8ADC;
  height: 600px;
  opacity: .40;
  margin: 0 0.938rem;
  width: 4px;
}

.controls {
  bottom: 0;
  display: flex;
  left: 2.5%;
  position: absolute;
  width: 95%;
}

.graphicToggle {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  text-transform: capitalize;
}

.graphicToggle button {
  background: none;
  border: none;
}
.graphicToggle button:first-of-type {
  margin-right: 3.125rem;
}
.graphicToggle button:last-of-type {
  margin-left: 3.125rem;
}

.graphicToggle div {
  align-items: center;
  display: flex;
}