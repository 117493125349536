/*
 * Styles to override devextreme <Gallery /> component native CSS
 */

.dx-item.dx-gallery-item {
  opacity: 0.25;
  transition: 0.3s linear;
  transform: scale(0.9);
}

.dx-item.dx-gallery-item.dx-item-selected.dx-gallery-item-selected {
  opacity: 1;
  transform: scale(1.1);
}

.dx-gallery .dx-gallery-nav-button-next:before {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  right: 20px!important;
  width: 50px!important;
  height: 50px!important;
  display: flex!important;
  justify-content: center;
  align-items: center;
}
.dx-gallery .dx-gallery-nav-button-next:after {
  display: none;
}

.dx-gallery .dx-gallery-nav-button-prev:before {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  left: 20px!important;
  width: 50px!important;
  height: 50px!important;
  display: flex!important;
  justify-content: center;
  align-items: center;
}
.dx-gallery .dx-gallery-nav-button-prev:after {
  display: none;
}
