.CommunityDropDown {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  transform: rotate(0);
  overflow: visible;
}

.dropDown {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  border: none;
  outline: none;
  overflow: visible;
  background-color: transparent;
}

.dDContainer {
  position: absolute;
  left: 3px;
  height: fit-content;
  width: 95%;
  /* background: rgb(200, 200, 200, .5); */
  border-top: none;
  list-style: none;
  padding: 0;
  border-radius: 5px;
  top: 18px;
  border-top: 1px solid black;
}

.listItems {
  list-style: none;
  font-family: 'Avenir';
  color: white;
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 104% 139%;
  /* margin-top: 2px; */
  background-color: white;
  overflow: visible;
}

.listItems:last-child {
  margin-bottom: 2px;
}
.checkmark {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 97% 99%;
  width: 3.5%;
  margin-left: 6px;
  align-self: center;
}

.communityText {
  height: 100%;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}