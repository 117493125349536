.BuilderCard {
  display: flex;
  flex-direction: column;
  /* flex: 0 0 75%; */
  height: 99%;
  width: 97%;
  border: 1px solid rgba(26, 52, 100, 0);
  border-radius: 5px;
  margin: 2% 5px 5px 2%;
  box-shadow: -0.1rem 0.1rem 0.3rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200);
  transition: all .3s ease-in-out;
  background-color: white;
  padding: 2px;
  transform: rotateX(0deg);
}

.BuilderCardWeb button {
  cursor: pointer;
}

.BuilderCardWeb button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.BuilderCardWeb > button:first-of-type {
  border: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1rem;
  text-transform: uppercase;
}

.BuilderCardWeb > div:nth-of-type(2) {
  /* border-bottom: 1px solid #bfbfbf; */
}

.BuilderCardWeb > div:nth-of-type(2) div {
  display: inline-block;
  text-align: center;
  width: 50%;
}

.BuilderCardWeb > div:nth-of-type(2) div {
  display: inline-block;
  text-align: center;
  width: 50%;
  font-size: 1rem;
  font-weight: 700;
  padding: .75rem 1rem;
}

.headlineDetails {
  /* display: flex; */
}

.headlineDetails > div:nth-of-type(1) {
  color: rgb(30,	60,	112);
  /* border-right: 1px solid #bfbfbf; */
  text-align:left !important;
  text-transform: capitalize;
}

.headlineDetails > div:nth-of-type(2) {
  display: inline-block;
  text-align: center;
  width: 50%;
  font-size: 1rem;
  font-weight: 700;
  padding: .75rem 1rem;
  color: rgb(176,	185,	29);
}

.underlineDetail {
  color:rgb(30,	60,	112);
  font-size: .5rem !important;
  font-weight: 400 !important;
}

.BuilderCardWeb > div:nth-of-type(3) {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}

.BuilderCardWeb > div:nth-of-type(3) span {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.BuilderCardWeb > div:nth-of-type(3) img {
  height: 30px;
  margin-bottom: 0.5rem;
}

.BuilderCardWeb > div:nth-of-type(3) div {
  color: rgb(30,	60,	112);
  font-weight: 400;
}

.BuilderCardWeb > div:last-of-type {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem 0.75rem 0.5rem;
}

.BuilderCardWeb > div:last-of-type button {
  background: #fff;
  border: 1px solid rgb(30,	60,	112);
  border-radius: 5px;
  /* box-shadow: 5px 5px 3px #f2f2f2; */
  color: rgb(30,	60,	112);
  flex: 1 1 0;
  font-size: 0.6rem;
  font-weight: 500;
  margin-right: 0.75rem;
  padding: 0.5rem 0.25rem;
}

.BuilderCardWeb > div:last-of-type button:last-child {
  margin-right: 0;
}

.BuilderCardActive {
  display: flex;
  flex-direction: column;
  /* flex: 0 0 75%; */
  height: 99%;
  /* width: 30%; */
  border: 1px solid rgba(26, 52, 100, 0);
  border-radius: 5px;
  margin: 5px 5px 5px 5px;
  box-shadow: -0.1rem 0.1rem 0.3rem rgba(26, 52, 100), 0.1rem 0.1rem 0.3rem rgba(26, 52, 100);
  transition: all .3s ease-in-out;
  background-color: white;
  padding: 2px;
  transform: rotateX(0deg);
}

.BuilderCardActive:hover {
  border: 1px solid rgba(26, 52, 100);
  transform: scale(1.02);
  transform: translateY(.5%);
}

.BuilderCard:hover {
  border: 1px solid rgba(26, 52, 100);
  transform: scale(1.02);
}
.cardHeader {
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  color: white;
}

.floorplanDetails {
  margin-left: .5rem;
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.floorplanName {
  display: flex;
  align-items: flex-end;
  /* color: rgb(26, 52, 100); */
  font-size: 1.8rem;
  flex: 0 1 60%;
  margin-bottom: -7px;
  font-weight: 500;
}

.floorplanBasePrice {
  display: flex;
  flex: 0 1 40%;
  align-items: flex-start;
  /* color: rgb(155, 155, 155); */
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: .8rem;
}

.modelImgContainer {
  position: relative;
}

.modelImgContainer img {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: block;
  width: 100%;
}

.BuilderCard .cardData {
  display: flex;
  flex-basis: 15%;
}

.roomDataInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 33%;
}

.dataNumber {
  color: rgb(105, 105, 105);
  font-weight: 500;
  font-size: 1.2rem;
}

.dataName {
  color: rgb(155, 155, 155);
  font-size: 0.8rem;
}

.BuilderCard .customize {
  flex: 0 1 auto;
  align-self: center;
  font-weight: 500;
  padding: 0.5rem;
  margin: 0.5rem;;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.BuilderCard .customize a {
  display: flex;
  text-decoration: none;
  color: rgb(195, 0, 0);
  flex: 0 0 60%;
}

.BuilderCard .customize:hover {
  background: rgb(240, 240, 240)
}

.squareImageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.floorplanSpecs {
  background-color: #101010;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  display: flex;
  flex-direction: row;
  height: 20%;
  width: 100%;
}

.detailContainer {
  border: 1px solid white;
  width: 30%;
  border-radius: 3px;
  color: white;
  display: flex;
  flex-direction: row;
  margin: 1.5%;
  text-align: center;  
  font-size: .7rem;
  align-items: center;
  justify-content: center;
}

.cardDetails{
  height: 37%;
}

.floorplanDatum {
  font-size: .7rem;
  align-self: center;
  display: flex;
  justify-content: center;
  width: 60%;
}

.floorplanDetailImg {
  width: 40%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  background-color: #101010;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid white;
}

.buttonContainer {
  display: flex;
  height: 50%;
}

.buttonContainer button {
  height: 100%;
  color: white;
  font-family: 'Avenir';
  font-size: 13px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  width:100%;
  border-radius: 5px;
}

.buttonLink:first-child {
  width: 24.8%;
  margin-right: 1%;
  margin-left: 0%;
}

.buttonLink {
  height: 85%;
  width: 24.8%;
  margin-right: 1%;
  margin-left: 1%;
  align-self: center;
  background-color: #242424;
  border-radius: 6px;
  border: 1px solid white;
  box-shadow: -0.1rem 0.1rem 0.2rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200);

}

.buttonLink:last-child {
  width: 24.8%;
  margin-left: 1%;
  margin-right: 0%;

}

/* .buttonLinkDisabled {
  height: 80%;
  width: 24.8%;
  margin-right: 0%;
  margin-left: 0%;
  align-self: center;
  background-color: #555555;
  border-radius: 6px;

} */

.navButton {
  /* box-shadow: -0.1rem 0.1rem 0.3rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200); */
  /* border-radius: 5px; */
  background-color: transparent;
  border-radius: 50%;
  border-width: 2px;
  border-color: transparent;
  border-style: solid;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  padding: 0px;
  /* border: 1px solid white;
  background-color: white; */
  font-size: .7rem !important;
}

.newBuild {
  height: 52%;
  width: 100%;
  border-radius: 3px;
  border: 1px solid gray;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 70%;
  background-color: #f0f0f0;
}

.homeBuilder{
  height: 48%;
  display: flex;
  flex-direction: column-reverse;
}

.homeBuilderButton{
  width: 100%;
  height: 100%;
  border: 1px solid white;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  align-self: end;
  box-shadow: -0.1rem 0.1rem 0.2rem rgb(200, 200, 200), 0.1rem 0.1rem 0.3rem rgb(200, 200, 200);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.configText {
  height: 100%;
  font-family: 'Avenir';
  color: white;
  font-size: 0.8rem;
  font-weight: 700;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.configIcon {
  height: 2.9vh;
  width: 5vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  margin-right: 5px;
}

.opaqueLayer {
  height: 98%;
  background-color: gray;
  position: absolute;
  width: 99%;
  z-index: 10;
  opacity: 0.7;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.centeredDiv {
  height: fit-content;
  background: white;
  font-size: 2rem;
  text-align: center;
}

.svgHolder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.communityText {
  font-family: Avenir-Black, Avenir;
  font-weight: "900";
}

.quickContainter {
  height: 11vh;
  width: 11vh;
  position: absolute;
  top: -2px;
  left: -2px;
  border-radius: 3px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  border: 1px solid transparent;
}

.quickMove {
  width: 0;
  height: 0;
  border-right: 6.5vh solid transparent;
  border-left: 6.5vh solid transparent;
  border-bottom: 6.5vh solid #6E914F;
  transform: rotate(-45deg);
  position: absolute;
  top: calc(-1.5vh);
  left: calc(-4.5vh);
  color: white;
}

.quickMoveTwo {
  width: 0;
  height: 0;
  border-right: 7vh solid transparent;
  border-left: 7vh solid transparent;
  border-bottom: 7vh solid white;
  transform: rotate(-45deg);
  position: absolute;
  top: calc(-1.5vh);
  left: calc(-4.9vh);
  color: white;
}

.quickMoveText {
  color: white;
  display: flex;
  position: absolute;
  top: 3vh;
  left: 4.2vh;
  transform: translate(-7.5vh, 0vh);
  height: 2vh;
  width: 6vh;
  text-align: center;
  font-size: .5rem;
}

.thumbnailDetail {
  line-height: 15px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.modelCircle {
  position: relative;
  margin-left: 0.1rem;
  margin-bottom: 1rem;
  border-radius: 50%;
  border: 1px white;
  border-style: solid;
  width: 14px;
  height: 14px;
  padding-top: 1px;
  line-height: 9px;
  font-size: 9px;
  text-align: center;
  background-color: rgb(33, 174, 250);
}
