.InfoPanel {
  background: #fff;
  border: 1px solid #1A1A1A;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .4);
  width: 220px;
}

.InfoPanel h1 {
  border-bottom: 1px solid #101010;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0.938rem 0.938rem 0.625rem 0.938rem;
  padding-bottom: 0.625rem;
  text-align: center;
}

.infoText {
  text-align: center;
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
}

.infoGraphicWrapper {
  display: flex;
}

.infoGraphic {
  align-items: center;
  display: flex;
  padding: 0 0.938rem 0.938rem 0.938rem;
  width: 50%;
}
.infoGraphic:last-child {
  justify-content: flex-end;
}

.infoGraphic img {
  margin-right: 0.625rem;
  width: 30px;
}

.infoGraphic span {
  white-space: nowrap;
}